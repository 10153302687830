<template>
  <div>
    <van-nav-bar
        :title="$t('streaming')"
        :border="false"
        fixed
        :placeholder="true"
        z-index="999"
    />
    <div v-if="!topicListLoaded" style="display: flex;justify-content: center">
      <van-loading size="16px" style="top: 50px">Loading...</van-loading>
    </div>

    <van-tabs v-show="topicListLoaded" v-model="firstActive" @click="firstChange" :ellipsis="false">
      <van-tab v-for="item in topicList.topic_list" :title="item.name" :key="item.name" :name="item.id">
        <van-divider/>

        <van-tabs v-model="secondActive" type="card" style="margin-top: 10px" :border="false" @click="secondTabChange">
          <van-tab v-for="pidInfo in topicList.pid_list" :title="pidInfo.name" :key="pidInfo.name" :name="pidInfo.pid">
            <van-list
                :v-model="loading"
                :finished="finished"
                :finished-text="$t('finish')"
                :loading-text="$t('loading')"
                :immediate-check="false"
                @load="onLoad"
            >
              <van-grid :column-num="3" :gutter="5" :border="false">
                <van-grid-item v-for="vodInfo in vodList" :key="vodInfo.id" @click="toPlays(vodInfo.id)">
                  <div style="position:relative">
                    <van-image :src="vodInfo.vod_pic" class="image_item" />
                    <audioLangTag v-show="$app_country === 'hi'" :content="vodInfo.audio_language_tag"/>
                  </div>
                  <div class="van-grid-item-text">
                    {{ vodInfo.vod_name }}
                  </div>
                </van-grid-item>
              </van-grid>
            </van-list>
          </van-tab>
        </van-tabs>
      </van-tab>
    </van-tabs>

    <layout></layout>
  </div>
</template>

<script>
import {topicList, topicVodList} from '@/api/Topic'
import noneImg from '@/assets/ic_default_varical.png' //三列图的默认图片
import layout from '@/layout/index'
import audioLangTag from "@/components/audioLangTag";

export default {
  name: "stream",
  components: {
    layout,
    audioLangTag
  },
  data() {
    return {
      topicListLoaded: false,
      topicList: {},
      vodList: [],
      loadingImg: noneImg,
      loading: false,
      finished: false,
      pid: 0,
      topicId: 0,
      pn: 1,
      secondActive: 0,
      firstActive: 0,
      cacheVodList: {}
    }
  },
  mounted() {
    this.getTopicList()
    this.$parent.excludeKeepAlive([])
  },
  methods: {
    onLoad() {
      if (this.loading) {
        return false
      }
      this.loading = true
      console.log(this.topicId, this.pid, this.pn, this.loading)
      this.getTopicVodList(this.topicId, this.pid, this.pn)
      this.pn = this.pn + 1
    },
    getTopicList() {
      topicList({"type_id": 1}).then(res => {
        let data = res.result

        this.topicList = data

        // 获取影片信息
        let pidList = data.pid_list
        let topicList = data.topic_list
        let pid = pidList[this.secondActive]['pid']
        let topicId = topicList[this.firstActive]['id']

        this.pid = pid
        this.topicId = topicId

        this.onLoad()
        this.loading = true
        this.topicListLoaded = true
      })
    },
    getTopicVodList(topicId, pid = 0, pn = 1) {
      // 获取缓存
      let cacheData = this.cacheVodList
      let isApi = true
      try {
        if (cacheData) {
          let data = cacheData[topicId][pid][pn]
          if (data) {
            isApi = false
            for (let item of data) {
              this.vodList.push(item)
            }
            this.loading = false
          }
        }
      } catch (e) {
        console.log(e.message);
      }


      if (isApi) {
        topicVodList({
          'topic_id': topicId,
          'pid': pid,
          'pn': pn,
          'psize': 20
        }).then(res => {
          let vod_list = res.result.vod_list
          if (vod_list.length > 0) {
            for (var item of res.result.vod_list) {
              this.vodList.push(item)
            }
            if (! cacheData[topicId]) {
              cacheData[topicId] = {}
            }
            if (! cacheData[topicId][pid]) {
              cacheData[topicId][pid] = {}
            }
            cacheData[topicId][pid][pn] = res.result.vod_list

            this.cacheVodList = cacheData
            this.loading = false
          } else {
            this.finished = true
          }
        })
      }
    },
    secondTabChange(name) {
      console.log(this.secondActive)
      this.pid = name
      this.pn = 1
      this.vodList = []
      this.finished = false
      this.loading = false
      this.onLoad()
    },
    firstChange(name) {
      console.log(this.firstActive)
      this.topicId = name
      this.secondActive = 0
      this.pid = 0
      this.pn = 1
      this.vodList = []
      this.loading = false
      this.finished = false
      this.onLoad()
    },
    // 去播放页面
    toPlays(vod_id) {
      const query = {
        vod_id,
      }
      this.$router.push({
        path: '/play',
        query
      })
    },
  }
}
</script>

<style scoped>

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 0.8rem;
}
/deep/ .van-tabs__nav--card {
  border: 0;
  padding-bottom: 0.2rem;
  margin-left: 10px;
}
/deep/ .van-tabs__nav--line.van-tabs__nav--complete{
  padding-right: 0;
  padding-left: 0;
}

/deep/ .van-tabs__nav--line {
  background-color: var(--main-color);
}

/*第一级未选中样式*/
/deep/ .van-tabs__nav--line .van-tab {
  font-size: 16px;
  font-weight: normal;
  color: var(--title-color);
  opacity: 0.7;
  white-space: nowrap;  /* 文字不折叠 */
}
/*第二级未选中样式*/
/deep/ .van-tabs__nav--card .van-tab {
  color:var(--title-color);
  opacity: 0.7;
  border-right: 0;
  font-size: 16px;
  font-weight: normal;
  -webkit-flex: none;
  padding: 0.3rem .3rem;
}

/*第一级选中样式*/
/deep/ .van-tabs__nav--line .van-tab.van-tab--active {
  font-size: 16px;
  color: var(--title-color);
  font-weight: bold;
  opacity: 1;
}

/*第二级选中样式*/
/deep/ .van-tabs__nav--card .van-tab.van-tab--active {
  background-color: var(--minor-color);
  border-radius: 4px;
  font-stretch: normal;
  color: var(--title-color);
  font-weight: bold;
  opacity: 1;
}

/deep/ .van-tabs--line .van-tabs__wrap {

}

/deep/ .van-tabs__line {
  height: 1px;
  border-radius: 1px;
  opacity: 1;
}

/deep/ .van-grid-item__content--center {
  padding: 0;
  background-color: var(--main-color);
}

/deep/ .van-grid-item {
  min-width: 0;
  color: var(--minor-text-color);
}

/deep/ .van-divider {
  margin: 0;
}

/deep/.van-nav-bar {
  border-bottom: 1px solid var(--minor-color);
}

/deep/ .van-nav-bar__title {
  font-weight: bold;
}

.van-grid-item-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}
.image_item{
  width: 116px;
  display: block;
  border-radius: 3px;
  overflow: hidden;
  height: 164px;
}
</style>
